import React from "react";
import Navbar from "./components/Navbar/Navbar";
import HomeCara from "./components/pages/Home/homecarsoul/HomeCara";
import Footer from "./components/Footer/Footer";
import { Route, Routes } from "react-router-dom";
import Aboutus from "./components/pages/about-us/Aboutus";
import Contact from "./components/pages/contact-us/Contact";
import BoneNut from "./components/pages/product/BoneNut";
import PageDetails from "./components/pages/product/PageDetails";
import ImageFullVeiw from "./components/pages/product/ImageFullVeiw";
import SearchResults from "./components/pages/product/SearchResults";
import NewSearch from "./components/pages/product/NewSearch";

const App = () => {

  return (
    <section
      className="h-screen bg-Hero bg-cover
      font-[Poppins] md:bg-top bg-center"
    >
      <Navbar />
      <Routes>
        <Route path="/" element={<HomeCara />} />
        <Route path="/about-us" element={<Aboutus />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/products?" element={<BoneNut />} />
        <Route path="/page-details" element={<ImageFullVeiw />} />
        <Route path="/product/details?" element={<PageDetails />} />
        <Route path="/searchproducts" element={<SearchResults />} />
        <Route path="/newsearch" element={<NewSearch />} />
      </Routes>
        <Footer />
     
    </section>
  );
};

export default App;
