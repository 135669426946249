import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                "Handicrafts Manufacturer": "Handicrafts Manufacturer",
                "buffalo bone": "buffalo bone",
                "welcome to": "Welcome to",
                "RC CREATION": "RC CREATION",
                "Bone & Horn, Wooden Handicrafts Manufacturer & Exporter Worldwide.": "Bone & Horn, Wooden Handicrafts Manufacturer & Exporter Worldwide.",
                "Knife Handle": "Knife Handle",
                "Bone Folder": "Bone Folder",
                "We provide premium quality Camel bone and water buffalo horn": "We provide premium quality Camel bone and water buffalo horn",
                "Guitar Component": "Guitar Component",
                "Dominoes Set": "Dominoes Set",
                "Wooden and Leather Handicraft Items, to make sure": "Wooden and Leather Handicraft Items, to make sure",
                "that these meet the industry quality standards": "that these meet the industry quality standards",
                "Horn Beads": "Horn Beads",
                "Bone Button": "Bone Button",
                "We provide premium quality Camel bone and water buffalo horn, Wooden and Leather Handicraft Items.": "We provide premium quality Camel bone and water buffalo horn, Wooden and Leather Handicraft Items.",
                "Bone Dice": "Bone Dice",
                "Bone Clasp": "Bone Clasp",
                "Latest": "Latest",
                "Products": "Products",
                "Knife handle image": "Knife handle image",
                "bone handle": "bone handle",
                "Our expertly crafted bone knife handles are made from premium animal bone, precision-fitted to ensure a comfortable grip and durable performance, perfect for discerning knife enthusiasts and collectors.": "Our expertly crafted bone knife handles are made from premium animal bone, precision-fitted to ensure a comfortable grip and durable performance, perfect for discerning knife enthusiasts and collectors.",
                "More Product": "More Product",
                "Bone folder image": "Bone folder image",
                "bone folder": "bone folder",
                "Our bone folder is a premium quality tool used by crafters and bookbinders alike. Our manufactured bone folders utilize high-quality materials, making them durable and precise.": "Our bone folder is a premium quality tool used by crafters and bookbinders alike. Our manufactured bone folders utilize high-quality materials, making them durable and precise.",
                "Bone dice image": "Bone dice image",
                "bone dice": "bone dice",
                "Add a touch of ancient charm to your tabletop games with our handcrafted Bone Dice. Made from natural bone, these unique dice bring a sense of history and mystique to your gameplay experience.": "Add a touch of ancient charm to your tabletop games with our handcrafted Bone Dice. Made from natural bone, these unique dice bring a sense of history and mystique to your gameplay experience.",
                "Guitar component image": "Guitar component image",
                "guitar component": "guitar component",
                "Discover the unique sound of our bone guitar components. Crafted from animal bone, the nut or saddle adds warmth and sustain, enhancing the overall tone of your instrument.": "Discover the unique sound of our bone guitar components. Crafted from animal bone, the nut or saddle adds warmth and sustain, enhancing the overall tone of your instrument.",
                "Pen blanks image": "Pen blanks image",
                "pen blanks": "pen blanks",
                "Create unique pens with our bone pen blanks, made from high-quality animal bone. Durable and distinctive, our blanks offer a variety of shapes and sizes to elevate your pen-making craft.": "Create unique pens with our bone pen blanks, made from high-quality animal bone. Durable and distinctive, our blanks offer a variety of shapes and sizes to elevate your pen-making craft.",
                "Drinking horn image": "Drinking horn image",
                "drinking horn": "drinking horn",
                "Unleash your wild side with our genuine animal drinking horns, crafted from naturally shed antlers and horns. A rustic, eco-friendly way to enjoy your favorite beverages.": "Unleash your wild side with our genuine animal drinking horns, crafted from naturally shed antlers and horns. A rustic, eco-friendly way to enjoy your favorite beverages.",
                // website page 
                "Our Vision & Experts, Quality": "Our Vision & Experts, Quality",
                "Vision": "Vision",
                "Our main priority is to attain absolute customer satisfaction for which our team works efficiently to keep the product's quality. Moreover, our team.": "Our main priority is to attain absolute customer satisfaction for which our team works efficiently to keep the product's quality. Moreover, our team.",
                "Experts": "Experts",
                "Our experts have a deep knowledge of dominant market trends. Therefore they have practiced enough to appreciate the exact obligation of the client.": "Our experts have a deep knowledge of dominant market trends. Therefore they have practiced enough to appreciate the exact obligation of the client.",
                "Quality": "Quality",
                "Apart from the compliance of different products with global quality standards, we have become a good choice for our clients. We focus more on ethical.": "Apart from the compliance of different products with global quality standards, we have become a good choice for our clients. We focus more on ethical.",
                "In promotion and advertising, a testimonial consists of a person's experience in a written statement extolling the integrity of a product or services.": "In promotion and advertising, a testimonial consists of a person's experience in a written statement extolling the integrity of a product or services.",
                "Get More Info.": "Get More Info.",

                "Welcome to our Website": "Welcome to our Website",
                "About us Located in Ghaziabad, Uttar Pradesh (India), we at Gift Mart was established in the year 2015.": "About us Located in Ghaziabad, Uttar Pradesh (India), we at Gift Mart was established in the year 2015.",
                "We are one of the preeminent Suppliers of exquisite range that includes Wooden and Resin Coaster Set, Natural Horn Comb, Bone Hair Comb, Wooden Hair Comb, Horn Drinking Mugs, Horn Bone Jewelry Box, Wooden MDF Box, Mother of Pearl Box, Buffalo Horn Box, Wooden Lamp and Buffalo Horn Toggles, etc.": "We are one of the preeminent Suppliers of exquisite range that includes Wooden and Resin Coaster Set, Natural Horn Comb, Bone Hair Comb, Wooden Hair Comb, Horn Drinking Mugs, Horn Bone Jewelry Box, Wooden MDF Box, Mother of Pearl Box, Buffalo Horn Box, Wooden Lamp and Buffalo Horn Toggles, etc.",
                "Apart from the compliance of different products with global quality standards, we have become a good choice for our clients. We focus more on ethical business policies and help the clients with open quality products. Additionally, to answer the needs of the clients, we make proper quality testing of the products at different levels. Our experts have a deep knowledge of dominant market trends.": "Apart from the compliance of different products with global quality standards, we have become a good choice for our clients. We focus more on ethical business policies and help the clients with open quality products. Additionally, to answer the needs of the clients, we make proper quality testing of the products at different levels. Our experts have a deep knowledge of dominant market trends.",
                "Read More.": "Read More.",

                "Popular": "Popular",
                "Products": "Products",
                "Camel Bone Dominoes": "Camel Bone Dominoes",
                "Many folks have asked us to expand our original 6x6 Dominos game into a 9x9 configuration and we aim to please! In this edition, we added 27 new tiles (for a total of 55 hand-carved dominos/set) essentially doubling the amount of dominos/set.": "Many folks have asked us to expand our original 6x6 Dominos game into a 9x9 configuration and we aim to please! In this edition, we added 27 new tiles (for a total of 55 hand-carved dominos/set) essentially doubling the amount of dominos/set.",
                "Bone Clasp": "Bone Clasp",
                "Bone clasps are useful to add the finishing touch to Japanese bookbinding styles and can be used in creative ways. These genuine bone clasps are one inch in length.": "Bone clasps are useful to add the finishing touch to Japanese bookbinding styles and can be used in creative ways. These genuine bone clasps are one inch in length.",
                "Dyed Bone Nut Blank": "Dyed Bone Nut Blank",
                "Unleash the tone of your guitar with our premium bone nuts, crafted from sustainably sourced animal bones. Our expert luthiers carefully select and shape each nut to bring out its unique characteristics, resulting in a rich, warm sound": "Unleash the tone of your guitar with our premium bone nuts, crafted from sustainably sourced animal bones. Our expert luthiers carefully select and shape each nut to bring out its unique characteristics, resulting in a rich, warm sound",
                "Guitar Bridge Pin": "Guitar Bridge Pin",
                "Whether crafted from camel bones, buffalo horns, or fossilized ivory, each piece is precision-made to perfectly complement your instrument.": "Whether crafted from camel bones, buffalo horns, or fossilized ivory, each piece is precision-made to perfectly complement your instrument.",
                "Guitar Slides": "Guitar Slides",
                "Transform your guitar's sound with our premium Guitar animals Inlay, crafted from sustainably sourced materials. Our skilled artisans carefully design each inlay to optimize resonance and sustain, delivering a rich, vibrant tone.": "Transform your guitar's sound with our premium Guitar animals Inlay, crafted from sustainably sourced materials. Our skilled artisans carefully design each inlay to optimize resonance and sustain, delivering a rich, vibrant tone.",
                "Camel Bone Knife Handle": "Camel Bone Knife Handle",
                "Our skilled artisans carefully design each component to optimize resonance and sustain, delivering a rich, vibrant tone. Whether crafted from intricate designs, exotic woods, or precious stones, each piece is precision-made to perfectly complement your instrument.": "Our skilled artisans carefully design each component to optimize resonance and sustain, delivering a rich, vibrant tone. Whether crafted from intricate designs, exotic woods, or precious stones, each piece is precision-made to perfectly complement your instrument.",
                "Water Buffalo Horn Smooth": "Water Buffalo Horn Smooth",
                "Enhance your instrument's sound with our premium Water Buffalo Horn, crafted from sustainably sourced materials. Our skilled artisans carefully design each component to optimize resonance and sustain, delivering a rich, vibrant tone": "Enhance your instrument's sound with our premium Water Buffalo Horn, crafted from sustainably sourced materials. Our skilled artisans carefully design each component to optimize resonance and sustain, delivering a rich, vibrant tone",
                "Recone True Stone": "Recone True Stone",
                "Elevate your performance with our premium Animals, Giraffe Bone Look, Recone True Stone and X-Grip Handle, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize comfort and control, delivering a confident, expressive show.": "Elevate your performance with our premium Animals, Giraffe Bone Look, Recone True Stone and X-Grip Handle, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize comfort and control, delivering a confident, expressive show.",
                "Stabilized Wood Handle": "Stabilized Wood Handle",
                "Elevate your performance with our premium Sheep Horn Handles, Ox-Zebu Horn Handles and Stabilized Wood Handle, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize comfort and control, delivering a confident, expressive show.": "Elevate your performance with our premium Sheep Horn Handles, Ox-Zebu Horn Handles and Stabilized Wood Handle, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize comfort and control, delivering a confident, expressive show.",
                "Buffalo Bone Hair Pipe Beads": "Buffalo Bone Hair Pipe Beads",
                "Real bone hair pipe beads are meticulously crafted from authentic bone, offering a unique and natural texture. Each bead is hand-carved to reveal intricate patterns and a warm, organic feel.": "Real bone hair pipe beads are meticulously crafted from authentic bone, offering a unique and natural texture. Each bead is hand-carved to reveal intricate patterns and a warm, organic feel.",
                "Teflon Bone Folder": "Teflon Bone Folder",
                "Elevate your bookbinding experience with our premium Teflon Bone Folder, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize precision and control, delivering a confident, professional finish.": "Elevate your bookbinding experience with our premium Teflon Bone Folder, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize precision and control, delivering a confident, professional finish.",
                "More Product": "More Product",

                "Let's Connect With Us": "Let's Connect With Us",
                "*Your Name": "*Your Name",
                "Full Name is Required": "Full Name is Required",
                "*E-Mail": "*E-Mail",
                "Enter your email": "Enter your email",
                "Please enter a valid email": "Please enter a valid email",
                "*Your Phone Number": "*Your Phone Number",
                "Phone number is required": "Phone number is required",
                "*Organization Name": "*Organization Name",
                "*Products/Service Looking For": "*Products/Service Looking For",
                "Please enter your products": "Please enter your products",
                "*Select Your Country": "*Select Your Country",
                "India": "India",
                "United States": "United States",
                "Japan": "Japan",
                "Australia": "Australia",
                "Canada": "Canada",
                "China": "China",
                "Germany": "Germany",
                "United Kingdom": "United Kingdom",
                "Russia": "Russia",
                "*Describe Your Requirements": "*Describe Your Requirements",
                "Enter your Message": "Enter your Message",
                "Submit": "Submit",
                "Success. Message sent successfully": "Success. Message sent successfully",
                "Something went wrong. Please try later.": "Something went wrong. Please try later.",
                "RC Creation Website Form": "RC Creation Website Form",
                "New Contact Message from your Website": "New Contact Message from your Website",
                "alt": "Work from home image",

                "Whatsapp Chat": "Whatsapp Chat",
                "RC Creation found 1985 was a cottage industries, now are the world wide largest Exporter , with an affirmation to manufacturing and retailing a high-quality range of Camel bone and water buffalo horn , Wooden and Leather Handicraft Items.": "RC Creation, founded in 1985, started as a cottage industry and is now the world's largest exporter, specializing in high-quality camel bone and water buffalo horn, wooden and leather handicraft items.",
                "Explore": "Explore",
                "About Us": "About Us",
                "Home": "Home",
                "Contact Us": "Contact Us",
                "Follow Us": "Follow Us",
                "Our Products": "Our Products",
                "Knife Handles": "Knife Handles",
                "Teflon Bone Folder": "Teflon Bone Folder",
                "Horn Saddle": "Horn Saddle",
                "Guitar Components": "Guitar Components",
                "Dominoes Set": "Dominoes Set",
                "Head Office Address": "Head Office Address",
                "Khasra No: 1703/1, Toli Mohalla, Mohalla Purvi Jatav, LONI – Ghaziabad-201102, UP, INDIA": "Khasra No: 1703/1, Toli Mohalla, Mohalla Purvi Jatav, LONI – Ghaziabad-201102, UP, INDIA",
                "+91 9266-116358": "+91 9266-116358",
                "+91 11-4227-3742": "+91 11-4227-3742",
                "info@bonehornmart.com": "info@bonehornmart.com",
                "rccreation.team@yahoo.com": "rccreation.team@yahoo.com",
                "Copyright &#169; 1985-2024": "Copyright © 1985-2024",
                "All Rights Reserved.": "All Rights Reserved."

            }
        },
        fr: {
            translation: {
                "Handicrafts Manufacturer": "Fabricant d'artisanat",
                "buffalo bone": "os de buffle",
                "welcome to": "Bienvenue à",
                "RC CREATION": "RC CREATION",
                "Bone & Horn, Wooden Handicrafts Manufacturer & Exporter Worldwide.": "Fabricant et exportateur mondial de produits artisanaux en os, corne et bois.",
                "Knife Handle": "Poignée de couteau",
                "Bone Folder": "Plieur d'os",
                "We provide premium quality Camel bone and water buffalo horn": "Nous fournissons des os de chameau et des cornes de buffle d'eau de qualité supérieure",
                "Guitar Component": "Composant de guitare",
                "Dominoes Set": "Jeu de dominos",
                "Wooden and Leather Handicraft Items, to make sure": "Articles artisanaux en bois et en cuir, pour s'assurer",
                "that these meet the industry quality standards": "qu'ils respectent les normes de qualité de l'industrie",
                "Horn Beads": "Perles de corne",
                "Bone Button": "Bouton en os",
                "We provide premium quality Camel bone and water buffalo horn, Wooden and Leather Handicraft Items.": "Nous fournissons des articles artisanaux en os de chameau et en corne de buffle d'eau, en bois et en cuir de qualité supérieure.",
                "Bone Dice": "Dés en os",
                "Bone Clasp": "Fermoir en os",

                "Latest": "Derniers",
                "Products": "Produits",
                "Knife handle image": "Image de la poignée du couteau",
                "bone handle": "poignée en os",
                "Our expertly crafted bone knife handles are made from premium animal bone, precision-fitted to ensure a comfortable grip and durable performance, perfect for discerning knife enthusiasts and collectors.": "Nos poignées de couteau en os, fabriquées avec soin, sont en os d'animaux de haute qualité, ajustées avec précision pour garantir une prise en main confortable et une performance durable, idéales pour les amateurs et collectionneurs de couteaux exigeants.",
                "More Product": "Plus de produits",
                "Bone folder image": "Image du plieur d'os",
                "bone folder": "plieur d'os",
                "Our bone folder is a premium quality tool used by crafters and bookbinders alike. Our manufactured bone folders utilize high-quality materials, making them durable and precise.": "Notre plieur d'os est un outil de qualité supérieure utilisé par les artisans et les relieurs. Nos plieurs d'os fabriqués utilisent des matériaux de haute qualité, les rendant durables et précis.",
                "Bone dice image": "Image des dés en os",
                "bone dice": "dés en os",
                "Add a touch of ancient charm to your tabletop games with our handcrafted Bone Dice. Made from natural bone, these unique dice bring a sense of history and mystique to your gameplay experience.": "Ajoutez une touche de charme ancien à vos jeux de société avec nos dés en os faits main. Fabriqués en os naturel, ces dés uniques apportent un sentiment d'histoire et de mystique à votre expérience de jeu.",
                "Guitar component image": "Image du composant de guitare",
                "guitar component": "composant de guitare",
                "Discover the unique sound of our bone guitar components. Crafted from animal bone, the nut or saddle adds warmth and sustain, enhancing the overall tone of your instrument.": "Découvrez le son unique de nos composants de guitare en os. Fabriqué en os d'animal, le sillet ou le chevalet ajoute de la chaleur et de la résonance, améliorant le ton général de votre instrument.",
                "Pen blanks image": "Image des ébauches de stylo",
                "pen blanks": "ébauches de stylo",
                "Create unique pens with our bone pen blanks, made from high-quality animal bone. Durable and distinctive, our blanks offer a variety of shapes and sizes to elevate your pen-making craft.": "Créez des stylos uniques avec nos ébauches de stylo en os, fabriquées à partir d'os d'animaux de haute qualité. Durables et distinctives, nos ébauches offrent une variété de formes et de tailles pour élever votre artisanat de fabrication de stylos.",
                "Drinking horn image": "Image du cor de boisson",
                "drinking horn": "cor de boisson",
                "Unleash your wild side with our genuine animal drinking horns, crafted from naturally shed antlers and horns. A rustic, eco-friendly way to enjoy your favorite beverages.": "Libérez votre côté sauvage avec nos véritables cors de boisson en animal, fabriqués à partir de bois et de cornes naturellement tombées. Une manière rustique et écologique de déguster vos boissons préférées.",

                "Our Vision & Experts, Quality": "Notre Vision & Experts, Qualité",
                "Vision": "Vision",
                "Our main priority is to attain absolute customer satisfaction for which our team works efficiently to keep the product's quality. Moreover, our team.": "Notre principale priorité est d'atteindre une satisfaction totale du client pour laquelle notre équipe travaille efficacement pour maintenir la qualité du produit. De plus, notre équipe.",
                "Experts": "Experts",
                "Our experts have a deep knowledge of dominant market trends. Therefore they have practiced enough to appreciate the exact obligation of the client.": "Nos experts ont une connaissance approfondie des tendances dominantes du marché. Par conséquent, ils ont suffisamment pratiqué pour apprécier l'exacte obligation du client.",
                "Quality": "Qualité",
                "Apart from the compliance of different products with global quality standards, we have become a good choice for our clients. We focus more on ethical.": "En plus de la conformité des différents produits aux normes de qualité mondiales, nous sommes devenus un bon choix pour nos clients. Nous mettons davantage l'accent sur l'éthique.",
                "In promotion and advertising, a testimonial consists of a person's experience in a written statement extolling the integrity of a product or services.": "En promotion et publicité, un témoignage consiste en l'expérience d'une personne dans une déclaration écrite louant l'intégrité d'un produit ou d'un service.",
                "Get More Info.": "Obtenez Plus d'Infos.",

                "Welcome to our Website": "Bienvenue sur notre site Web",
                "About us Located in Ghaziabad, Uttar Pradesh (India), we at Gift Mart was established in the year 2015.": "À propos de nous Situé à Ghaziabad, Uttar Pradesh (Inde), nous, chez Gift Mart, avons été établis en 2015.",
                "We are one of the preeminent Suppliers of exquisite range that includes Wooden and Resin Coaster Set, Natural Horn Comb, Bone Hair Comb, Wooden Hair Comb, Horn Drinking Mugs, Horn Bone Jewelry Box, Wooden MDF Box, Mother of Pearl Box, Buffalo Horn Box, Wooden Lamp and Buffalo Horn Toggles, etc.": "Nous sommes l'un des principaux fournisseurs d'une gamme exquise comprenant des ensembles de sous-verres en bois et en résine, des peignes en corne naturelle, des peignes en os, des peignes en bois, des mugs en corne, des boîtes à bijoux en corne, des boîtes en MDF en bois, des boîtes en nacre, des boîtes en corne de buffle, des lampes en bois et des attaches en corne de buffle, etc.",
                "Apart from the compliance of different products with global quality standards, we have become a good choice for our clients. We focus more on ethical business policies and help the clients with open quality products. Additionally, to answer the needs of the clients, we make proper quality testing of the products at different levels. Our experts have a deep knowledge of dominant market trends.": "En plus de la conformité des différents produits aux normes de qualité mondiales, nous sommes devenus un bon choix pour nos clients. Nous mettons davantage l'accent sur les politiques commerciales éthiques et aidons les clients avec des produits de qualité ouverte. De plus, pour répondre aux besoins des clients, nous effectuons des tests de qualité appropriés des produits à différents niveaux. Nos experts ont une connaissance approfondie des tendances dominantes du marché.",
                "Read More.": "Lire la suite.",

                "Our":"Notre",
                "Shipping Partners": "Partenaires de Livraison",
                "Shipping partner": "Partenaire de Livraison",

                "Popular": "Populaire",
                "Products": "Produits",
                "Camel Bone Dominoes": "Dominos en Os de Chameau",
                "Many folks have asked us to expand our original 6x6 Dominos game into a 9x9 configuration and we aim to please! In this edition, we added 27 new tiles (for a total of 55 hand-carved dominos/set) essentially doubling the amount of dominos/set.": "De nombreuses personnes nous ont demandé d'élargir notre jeu de dominos 6x6 original à une configuration 9x9, et nous nous efforçons de satisfaire cette demande ! Dans cette édition, nous avons ajouté 27 nouveaux carreaux (pour un total de 55 dominos sculptés à la main / ensemble), doublant essentiellement le nombre de dominos / ensemble.",
                "Bone Clasp": "Attache en Os",
                "Bone clasps are useful to add the finishing touch to Japanese bookbinding styles and can be used in creative ways. These genuine bone clasps are one inch in length.": "Les attaches en os sont utiles pour ajouter la touche finale aux styles de reliure japonaise et peuvent être utilisées de manière créative. Ces attaches en os véritable mesurent un pouce de longueur.",
                "Dyed Bone Nut Blank": "Ébauche de Sillet en Os Teint",
                "Unleash the tone of your guitar with our premium bone nuts, crafted from sustainably sourced animal bones. Our expert luthiers carefully select and shape each nut to bring out its unique characteristics, resulting in a rich, warm sound": "Libérez le ton de votre guitare avec nos sillets en os de première qualité, fabriqués à partir de os d'animaux provenant de sources durables. Nos luthiers experts sélectionnent et façonnent soigneusement chaque sillet pour révéler ses caractéristiques uniques, produisant un son riche et chaleureux.",
                "Guitar Bridge Pin": "Goupille de Chevalet pour Guitare",
                "Whether crafted from camel bones, buffalo horns, or fossilized ivory, each piece is precision-made to perfectly complement your instrument.": "Que ce soit en os de chameau, en corne de buffle ou en ivoire fossilisé, chaque pièce est fabriquée avec précision pour compléter parfaitement votre instrument.",
                "Guitar Slides": "Glissières pour Guitare",
                "Transform your guitar's sound with our premium Guitar animals Inlay, crafted from sustainably sourced materials. Our skilled artisans carefully design each inlay to optimize resonance and sustain, delivering a rich, vibrant tone.": "Transformez le son de votre guitare avec notre Incrustation pour Animaux de Guitare, fabriquée à partir de matériaux provenant de sources durables. Nos artisans qualifiés conçoivent soigneusement chaque incrustation pour optimiser la résonance et la durée, offrant un ton riche et vibrant.",
                "Camel Bone Knife Handle": "Poignée de Couteau en Os de Chameau",
                "Our skilled artisans carefully design each component to optimize resonance and sustain, delivering a rich, vibrant tone. Whether crafted from intricate designs, exotic woods, or precious stones, each piece is precision-made to perfectly complement your instrument.": "Nos artisans qualifiés conçoivent soigneusement chaque composant pour optimiser la résonance et la durée, offrant un ton riche et vibrant. Que ce soit fabriqué à partir de designs complexes, de bois exotiques ou de pierres précieuses, chaque pièce est fabriquée avec précision pour compléter parfaitement votre instrument.",
                "Water Buffalo Horn Smooth": "Corne de Buffle d'Eau Lisse",
                "Enhance your instrument's sound with our premium Water Buffalo Horn, crafted from sustainably sourced materials. Our skilled artisans carefully design each component to optimize resonance and sustain, delivering a rich, vibrant tone": "Améliorez le son de votre instrument avec notre Corne de Buffle d'Eau de première qualité, fabriquée à partir de matériaux provenant de sources durables. Nos artisans qualifiés conçoivent soigneusement chaque composant pour optimiser la résonance et la durée, offrant un ton riche et vibrant.",
                "Recone True Stone": "Recone Pierre Véritable",
                "Elevate your performance with our premium Animals, Giraffe Bone Look, Recone True Stone and X-Grip Handle, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize comfort and control, delivering a confident, expressive show.": "Élevez votre performance avec nos produits premium Animaux, Aspect Os de Girafe, Pierre Véritable Recone et Poignée X-Grip, fabriqués à partir de matériaux soigneusement sélectionnés. Nos artisans qualifiés conçoivent chaque élément avec soin pour maximiser le confort et le contrôle, offrant un spectacle confiant et expressif.",
                "Stabilized Wood Handle": "Poignée en Bois Stabilisé",
                "Elevate your performance with our premium Sheep Horn Handles, Ox-Zebu Horn Handles and Stabilized Wood Handle, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize comfort and control, delivering a confident, expressive show.": "Élevez votre performance avec nos poignées en Corne de Mouton, en Corne d'Ox-Zebu et en Bois Stabilisé de première qualité, fabriquées à partir de matériaux soigneusement sélectionnés. Nos artisans qualifiés conçoivent chaque élément avec soin pour maximiser le confort et le contrôle, offrant un spectacle confiant et expressif.",
                "Buffalo Bone Hair Pipe Beads": "Perles de Tube en Os de Buffle",
                "Real bone hair pipe beads are meticulously crafted from authentic bone, offering a unique and natural texture. Each bead is hand-carved to reveal intricate patterns and a warm, organic feel.": "Les perles de tube en os véritable sont méticuleusement fabriquées à partir d'os authentiques, offrant une texture unique et naturelle. Chaque perle est sculptée à la main pour révéler des motifs complexes et une sensation organique chaleureuse.",
                "Teflon Bone Folder": "Plieuse en Os Teflon",
                "Elevate your bookbinding experience with our premium Teflon Bone Folder, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize precision and control, delivering a confident, professional finish.": "Améliorez votre expérience de reliure avec notre Plieuse en Os Teflon de première qualité, fabriquée à partir de matériaux soigneusement sélectionnés. Nos artisans qualifiés conçoivent chaque élément avec soin pour maximiser la précision et le contrôle, offrant une finition professionnelle confiante.",
                "More Product": "Plus de Produits",

                "Let's Connect With Us": "Connectons-nous avec nous",
                "*Your Name": "*Votre nom",
                "Full Name is Required": "Le nom complet est requis",
                "*E-Mail": "*E-Mail",
                "Enter your email": "Entrez votre e-mail",
                "Please enter a valid email": "Veuillez entrer un e-mail valide",
                "*Your Phone Number": "*Votre numéro de téléphone",
                "Phone number is required": "Le numéro de téléphone est requis",
                "*Organization Name": "*Nom de l'organisation",
                "*Products/Service Looking For": "*Produits/Service Recherché",
                "Please enter your products": "Veuillez entrer vos produits",
                "*Select Your Country": "*Sélectionnez votre pays",
                "India": "Inde",
                "United States": "États-Unis",
                "Japan": "Japon",
                "Australia": "Australie",
                "Canada": "Canada",
                "China": "Chine",
                "Germany": "Allemagne",
                "United Kingdom": "Royaume-Uni",
                "Russia": "Russie",
                "*Describe Your Requirements": "*Décrivez vos exigences",
                "Enter your Message": "Entrez votre message",
                "Submit": "Soumettre",
                "Success. Message sent successfully": "Succès. Message envoyé avec succès",
                "Something went wrong. Please try later.": "Une erreur s'est produite. Veuillez réessayer plus tard.",
                "RC Creation Website Form": "Formulaire de site Web RC Creation",
                "New Contact Message from your Website": "Nouveau message de contact de votre site Web",
                "alt": "Image du travail à domicile",

                "Whatsapp Chat": "Chat Whatsapp",
                "RC Creation found 1985 was a cottage industries, now are the world wide largest Exporter , with an affirmation to manufacturing and retailing a high-quality range of Camel bone and water buffalo horn , Wooden and Leather Handicraft Items.": "RC Creation, fondée en 1985, a commencé comme une industrie artisanale et est maintenant le plus grand exportateur mondial, spécialisé dans les articles d'artisanat de haute qualité en os de chameau et de buffle d'eau, en bois et en cuir.",
                "Explore": "Explorer",
                "About Us": "À Propos De Nous",
                "Home": "Accueil",
                "Contact Us": "Contactez-Nous",
                "Follow Us": "Suivez-Nous",
                "Our Products": "Nos Produits",
                "Knife Handles": "Manches de Couteau",
                "Teflon Bone Folder": "Plieur d'Os en Téflon",
                "Horn Saddle": "Selle en Corne",
                "Guitar Components": "Composants de Guitare",
                "Dominoes Set": "Jeu de Dominos",
                "Head Office Address": "Adresse du Siège",
                "Khasra No: 1703/1, Toli Mohalla, Mohalla Purvi Jatav, LONI – Ghaziabad-201102, UP, INDIA": "Khasra No: 1703/1, Toli Mohalla, Mohalla Purvi Jatav, LONI – Ghaziabad-201102, UP, INDE",
                "+91 9266-116358": "+91 9266-116358",
                "+91 11-4227-3742": "+91 11-4227-3742",
                "info@bonehornmart.com": "info@bonehornmart.com",
                "rccreation.team@yahoo.com": "rccreation.team@yahoo.com",
                "Copyright &#169; 1985-2024": "Copyright © 1985-2024",
                "All Rights Reserved.": "Tous Droits Réservés."


            }
        },
        es: {
            translation: {
                "Handicrafts Manufacturer": "Fabricante de artesanías",
                "buffalo bone": "hueso de búfalo",
                "welcome to": "Bienvenido a",
                "RC CREATION": "RC CREATION",
                "Bone & Horn, Wooden Handicrafts Manufacturer & Exporter Worldwide.": "Fabricante y exportador mundial de productos artesanales en hueso, cuerno y madera.",
                "Knife Handle": "Mango de cuchillo",
                "Bone Folder": "Pliegue de hueso",
                "We provide premium quality Camel bone and water buffalo horn": "Proveemos hueso de camello y cuerno de búfalo de agua de primera calidad",
                "Guitar Component": "Componente de guitarra",
                "Dominoes Set": "Juego de dominó",
                "Wooden and Leather Handicraft Items, to make sure": "Artículos artesanales de madera y cuero, para asegurar",
                "that these meet the industry quality standards": "que cumplan con los estándares de calidad de la industria",
                "Horn Beads": "Cuentas de cuerno",
                "Bone Button": "Botón de hueso",
                "We provide premium quality Camel bone and water buffalo horn, Wooden and Leather Handicraft Items.": "Proveemos artículos artesanales de hueso de camello, cuerno de búfalo de agua, madera y cuero de primera calidad.",
                "Bone Dice": "Dados de hueso",
                "Bone Clasp": "Broche de hueso",

                "Latest": "Últimos",
                "Products": "Productos",
                "Knife handle image": "Imagen del mango del cuchillo",
                "bone handle": "mango de hueso",
                "Our expertly crafted bone knife handles are made from premium animal bone, precision-fitted to ensure a comfortable grip and durable performance, perfect for discerning knife enthusiasts and collectors.": "Nuestros mangos de cuchillo de hueso, cuidadosamente elaborados, están hechos de hueso de animal de primera calidad, ajustados con precisión para garantizar un agarre cómodo y un rendimiento duradero, perfectos para entusiastas y coleccionistas de cuchillos exigentes.",
                "More Product": "Más productos",
                "Bone folder image": "Imagen del pliegue de hueso",
                "bone folder": "pliegue de hueso",
                "Our bone folder is a premium quality tool used by crafters and bookbinders alike. Our manufactured bone folders utilize high-quality materials, making them durable and precise.": "Nuestro pliegue de hueso es una herramienta de calidad superior utilizada por artesanos y encuadernadores por igual. Nuestros pliegues de hueso fabricados utilizan materiales de alta calidad, lo que los hace duraderos y precisos.",
                "Bone dice image": "Imagen de los dados de hueso",
                "bone dice": "dados de hueso",
                "Add a touch of ancient charm to your tabletop games with our handcrafted Bone Dice. Made from natural bone, these unique dice bring a sense of history and mystique to your gameplay experience.": "Agrega un toque de encanto antiguo a tus juegos de mesa con nuestros dados de hueso hechos a mano. Hechos de hueso natural, estos dados únicos aportan un sentido de historia y misticismo a tu experiencia de juego.",
                "Guitar component image": "Imagen del componente de guitarra",
                "guitar component": "componente de guitarra",
                "Discover the unique sound of our bone guitar components. Crafted from animal bone, the nut or saddle adds warmth and sustain, enhancing the overall tone of your instrument.": "Descubre el sonido único de nuestros componentes de guitarra de hueso. Hecho de hueso de animal, el alma o el puente añade calidez y sustentación, mejorando el tono general de tu instrumento.",
                "Pen blanks image": "Imagen de las maderas de bolígrafo",
                "pen blanks": "maderas de bolígrafo",
                "Create unique pens with our bone pen blanks, made from high-quality animal bone. Durable and distinctive, our blanks offer a variety of shapes and sizes to elevate your pen-making craft.": "Crea bolígrafos únicos con nuestras maderas de bolígrafo de hueso, hechas de hueso de animal de alta calidad. Duraderas y distintivas, nuestras maderas ofrecen una variedad de formas y tamaños para elevar tu arte de fabricación de bolígrafos.",
                "Drinking horn image": "Imagen del cuerno para beber",
                "drinking horn": "cuerno para beber",
                "Unleash your wild side with our genuine animal drinking horns, crafted from naturally shed antlers and horns. A rustic, eco-friendly way to enjoy your favorite beverages.": "Desata tu lado salvaje con nuestros auténticos cuernos para beber de animales, fabricados a partir de astas y cuernos naturalmente caídos. Una forma rústica y ecológica de disfrutar tus bebidas favoritas.",

                "Our Vision & Experts, Quality": "Nuestra Visión & Expertos, Calidad",
                "Vision": "Visión",
                "Our main priority is to attain absolute customer satisfaction for which our team works efficiently to keep the product's quality. Moreover, our team.": "Nuestra principal prioridad es alcanzar una satisfacción total del cliente para lo cual nuestro equipo trabaja de manera eficiente para mantener la calidad del producto. Además, nuestro equipo.",
                "Experts": "Expertos",
                "Our experts have a deep knowledge of dominant market trends. Therefore they have practiced enough to appreciate the exact obligation of the client.": "Nuestros expertos tienen un conocimiento profundo de las tendencias dominantes del mercado. Por lo tanto, han practicado lo suficiente para apreciar la obligación exacta del cliente.",
                "Quality": "Calidad",
                "Apart from the compliance of different products with global quality standards, we have become a good choice for our clients. We focus more on ethical.": "Además del cumplimiento de diferentes productos con las normas de calidad globales, nos hemos convertido en una buena opción para nuestros clientes. Nos enfocamos más en la ética.",
                "In promotion and advertising, a testimonial consists of a person's experience in a written statement extolling the integrity of a product or services.": "En promoción y publicidad, un testimonio consiste en la experiencia de una persona en una declaración escrita alabando la integridad de un producto o servicio.",
                "Get More Info.": "Obtener Más Información.",

                "Welcome to our Website": "Bienvenido a nuestro sitio web",
                "About us Located in Ghaziabad, Uttar Pradesh (India), we at Gift Mart was established in the year 2015.": "Sobre nosotros Ubicados en Ghaziabad, Uttar Pradesh (India), en Gift Mart, nos establecimos en el año 2015.",
                "We are one of the preeminent Suppliers of exquisite range that includes Wooden and Resin Coaster Set, Natural Horn Comb, Bone Hair Comb, Wooden Hair Comb, Horn Drinking Mugs, Horn Bone Jewelry Box, Wooden MDF Box, Mother of Pearl Box, Buffalo Horn Box, Wooden Lamp and Buffalo Horn Toggles, etc.": "Somos uno de los principales proveedores de una gama exquisita que incluye juegos de posavasos de madera y resina, peine de cuerno natural, peine de hueso, peine de madera, tazas de cuerno, caja de joyería de cuerno, caja de MDF de madera, caja de madreperla, caja de cuerno de búfalo, lámpara de madera y cierres de cuerno de búfalo, etc.",
                "Apart from the compliance of different products with global quality standards, we have become a good choice for our clients. We focus more on ethical business policies and help the clients with open quality products. Additionally, to answer the needs of the clients, we make proper quality testing of the products at different levels. Our experts have a deep knowledge of dominant market trends.": "Además del cumplimiento de diferentes productos con las normas de calidad globales, nos hemos convertido en una buena opción para nuestros clientes. Nos enfocamos más en las políticas comerciales éticas y ayudamos a los clientes con productos de calidad abierta. Además, para responder a las necesidades de los clientes, realizamos pruebas de calidad adecuadas de los productos en diferentes niveles. Nuestros expertos tienen un profundo conocimiento de las tendencias dominantes del mercado.",
                "Read More.": "Leer más.",

                "Our": "Nuestro",
                "Shipping Partners": "Socios de Envío",
                "Shipping partner": "Socio de Envío",

                "Popular": "Popular",
                "Products": "Productos",
                "Camel Bone Dominoes": "Dominós de Hueso de Camello",
                "Many folks have asked us to expand our original 6x6 Dominos game into a 9x9 configuration and we aim to please! In this edition, we added 27 new tiles (for a total of 55 hand-carved dominos/set) essentially doubling the amount of dominos/set.": "Muchas personas nos han pedido que ampliemos nuestro juego de dominós 6x6 original a una configuración 9x9 y ¡nos esforzamos por complacer! En esta edición, agregamos 27 nuevas fichas (para un total de 55 dominós tallados a mano/conjunto), duplicando esencialmente la cantidad de dominós/conjunto.",
                "Bone Clasp": "Broche de Hueso",
                "Bone clasps are useful to add the finishing touch to Japanese bookbinding styles and can be used in creative ways. These genuine bone clasps are one inch in length.": "Los broches de hueso son útiles para dar el toque final a los estilos de encuadernación japonesa y se pueden usar de manera creativa. Estos broches de hueso genuino tienen una pulgada de largo.",
                "Dyed Bone Nut Blank": "Sillet de Os Teñido",
                "Unleash the tone of your guitar with our premium bone nuts, crafted from sustainably sourced animal bones. Our expert luthiers carefully select and shape each nut to bring out its unique characteristics, resulting in a rich, warm sound": "Libera el tono de tu guitarra con nuestros sillets de hueso de primera calidad, fabricados a partir de huesos de animales provenientes de fuentes sostenibles. Nuestros luthiers expertos seleccionan y dan forma a cada sillet para resaltar sus características únicas, resultando en un sonido rico y cálido.",
                "Guitar Bridge Pin": "Clavija de Puente para Guitarra",
                "Whether crafted from camel bones, buffalo horns, or fossilized ivory, each piece is precision-made to perfectly complement your instrument.": "Ya sea fabricado con huesos de camello, cuernos de búfalo o marfil fossilizado, cada pieza está hecha con precisión para complementar perfectamente tu instrumento.",
                "Guitar Slides": "Deslizadores para Guitarra",
                "Transform your guitar's sound with our premium Guitar animals Inlay, crafted from sustainably sourced materials. Our skilled artisans carefully design each inlay to optimize resonance and sustain, delivering a rich, vibrant tone.": "Transforma el sonido de tu guitarra con nuestra Incrustación para Animales de Guitarra de primera calidad, fabricada a partir de materiales sostenibles. Nuestros artesanos calificados diseñan cuidadosamente cada incrustación para optimizar la resonancia y la duración, ofreciendo un tono rico y vibrante.",
                "Camel Bone Knife Handle": "Mango de Cuchillo de Hueso de Camello",
                "Our skilled artisans carefully design each component to optimize resonance and sustain, delivering a rich, vibrant tone. Whether crafted from intricate designs, exotic woods, or precious stones, each piece is precision-made to perfectly complement your instrument.": "Nuestros artesanos calificados diseñan cuidadosamente cada componente para optimizar la resonancia y la duración, ofreciendo un tono rico y vibrante. Ya sea fabricado con diseños intrincados, maderas exóticas o piedras preciosas, cada pieza está hecha con precisión para complementar perfectamente tu instrumento.",
                "Water Buffalo Horn Smooth": "Cuerno de Búfalo de Agua Liso",
                "Enhance your instrument's sound with our premium Water Buffalo Horn, crafted from sustainably sourced materials. Our skilled artisans carefully design each component to optimize resonance and sustain, delivering a rich, vibrant tone": "Mejora el sonido de tu instrumento con nuestro Cuerno de Búfalo de Agua de primera calidad, fabricado a partir de materiales sostenibles. Nuestros artesanos calificados diseñan cuidadosamente cada componente para optimizar la resonancia y la duración, ofreciendo un tono rico y vibrante.",
                "Recone True Stone": "Recone Piedra Verdadera",
                "Elevate your performance with our premium Animals, Giraffe Bone Look, Recone True Stone and X-Grip Handle, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize comfort and control, delivering a confident, expressive show.": "Eleva tu actuación con nuestros productos premium Animales, Aspecto de Hueso de Jirafa, Piedra Verdadera Recone y Mango X-Grip, fabricados con materiales cuidadosamente seleccionados. Nuestros artesanos calificados diseñan meticulosamente cada elemento para maximizar la comodidad y el control, ofreciendo un espectáculo seguro y expresivo.",
                "Stabilized Wood Handle": "Mango de Madera Estabilizada",
                "Elevate your performance with our premium Sheep Horn Handles, Ox-Zebu Horn Handles and Stabilized Wood Handle, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize comfort and control, delivering a confident, expressive show.": "Eleva tu actuación con nuestros Mangos de Cuerno de Oveja, Mangos de Cuerno de Ox-Zebu y Mango de Madera Estabilizada de primera calidad, fabricados con materiales cuidadosamente seleccionados. Nuestros artesanos calificados diseñan meticulosamente cada elemento para maximizar la comodidad y el control, ofreciendo un espectáculo seguro y expresivo.",
                "Buffalo Bone Hair Pipe Beads": "Cuentas de Tubo de Hueso de Búfalo",
                "Real bone hair pipe beads are meticulously crafted from authentic bone, offering a unique and natural texture. Each bead is hand-carved to reveal intricate patterns and a warm, organic feel.": "Las cuentas de tubo de hueso real están meticulosamente fabricadas a partir de hueso auténtico, ofreciendo una textura única y natural. Cada cuenta está esculpida a mano para revelar patrones intrincados y una sensación orgánica cálida.",
                "Teflon Bone Folder": "Pliegue de Hueso de Teflón",
                "Elevate your bookbinding experience with our premium Teflon Bone Folder, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize precision and control, delivering a confident, professional finish.": "Mejora tu experiencia de encuadernación con nuestro Pliegue de Hueso de Teflón de primera calidad, fabricado con materiales cuidadosamente seleccionados. Nuestros artesanos calificados diseñan meticulosamente cada elemento para maximizar la precisión y el control, ofreciendo un acabado profesional y seguro.",
                "More Product": "Más Productos",

                "Let's Connect With Us": "Conectémonos con nosotros",
                "*Your Name": "*Tu nombre",
                "Full Name is Required": "Se requiere el nombre completo",
                "*E-Mail": "*Correo electrónico",
                "Enter your email": "Introduce tu correo electrónico",
                "Please enter a valid email": "Por favor introduce un correo electrónico válido",
                "*Your Phone Number": "*Tu número de teléfono",
                "Phone number is required": "Se requiere número de teléfono",
                "*Organization Name": "*Nombre de la organización",
                "*Products/Service Looking For": "*Productos/Servicio que buscas",
                "Please enter your products": "Por favor ingrese sus productos",
                "*Select Your Country": "*Seleccione su país",
                "India": "India",
                "United States": "Estados Unidos",
                "Japan": "Japón",
                "Australia": "Australia",
                "Canada": "Canadá",
                "China": "China",
                "Germany": "Alemania",
                "United Kingdom": "Reino Unido",
                "Russia": "Rusia",
                "*Describe Your Requirements": "*Describe tus requisitos",
                "Enter your Message": "Introduce tu mensaje",
                "Submit": "Enviar",
                "Success. Message sent successfully": "Éxito. Mensaje enviado con éxito",
                "Something went wrong. Please try later.": "Algo salió mal. Por favor intenta más tarde.",
                "RC Creation Website Form": "Formulario de sitio web de RC Creation",
                "New Contact Message from your Website": "Nuevo mensaje de contacto desde su sitio web",
                "alt": "Imagen de trabajo desde casa",

                "Whatsapp Chat": "Chat de Whatsapp",
                "RC Creation found 1985 was a cottage industries, now are the world wide largest Exporter , with an affirmation to manufacturing and retailing a high-quality range of Camel bone and water buffalo horn , Wooden and Leather Handicraft Items.": "RC Creation, fundada en 1985, comenzó como una industria artesanal y ahora es el mayor exportador mundial, especializado en artículos de artesanía de alta calidad de hueso de camello y cuerno de búfalo de agua, madera y cuero.",
                "Explore": "Explorar",
                "About Us": "Sobre Nosotros",
                "Home": "Inicio",
                "Contact Us": "Contáctenos",
                "Follow Us": "Síguenos",
                "Our Products": "Nuestros Productos",
                "Knife Handles": "Mangos de Cuchillos",
                "Teflon Bone Folder": "Plegadora de Hueso de Teflón",
                "Horn Saddle": "Silla de Montar de Cuerno",
                "Guitar Components": "Componentes de Guitarra",
                "Dominoes Set": "Juego de Dominó",
                "Head Office Address": "Dirección de la Oficina Central",
                "Khasra No: 1703/1, Toli Mohalla, Mohalla Purvi Jatav, LONI – Ghaziabad-201102, UP, INDIA": "Khasra No: 1703/1, Toli Mohalla, Mohalla Purvi Jatav, LONI – Ghaziabad-201102, UP, INDIA",
                "+91 9266-116358": "+91 9266-116358",
                "+91 11-4227-3742": "+91 11-4227-3742",
                "info@bonehornmart.com": "info@bonehornmart.com",
                "rccreation.team@yahoo.com": "rccreation.team@yahoo.com",
                "Copyright &#169; 1985-2024": "Copyright © 1985-2024",
                "All Rights Reserved.": "Todos los Derechos Reservados."



            }
        },
        jn: {
            translation: {
                "Handicrafts Manufacturer": "手工艺品制造商",
                "buffalo bone": "水牛骨",
                "welcome to": "ようこそ",
                "RC CREATION": "RC CREATION",
                "Bone & Horn, Wooden Handicrafts Manufacturer & Exporter Worldwide.": "骨＆角、木製の手工芸品の製造および輸出業者",
                "Knife Handle": "ナイフの柄",
                "Bone Folder": "骨のフォルダー",
                "We provide premium quality Camel bone and water buffalo horn": "最高品質のラクダの骨と水牛の角を提供します",
                "Guitar Component": "ギターコンポーネント",
                "Dominoes Set": "ドミノセット",
                "Wooden and Leather Handicraft Items, to make sure": "木製および革製の手工芸品であることを確認するため",
                "that these meet the industry quality standards": "これらが業界の品質基準を満たしていること",
                "Horn Beads": "角ビーズ",
                "Bone Button": "骨のボタン",
                "We provide premium quality Camel bone and water buffalo horn, Wooden and Leather Handicraft Items.": "最高品質のラクダの骨と水牛の角、木製および革製の手工芸品を提供します。",
                "Bone Dice": "骨のサイコロ",
                "Bone Clasp": "骨の留め具",

                "Latest": "最新",
                "Products": "製品",
                "Knife handle image": "ナイフハンドルの画像",
                "bone handle": "骨のハンドル",
                "Our expertly crafted bone knife handles are made from premium animal bone, precision-fitted to ensure a comfortable grip and durable performance, perfect for discerning knife enthusiasts and collectors.": "当社の熟練した骨のナイフハンドルは、プレミアムな動物の骨で作られ、精密にフィットして快適なグリップと耐久性のあるパフォーマンスを保証します。目の肥えたナイフ愛好者やコレクターに最適です。",
                "More Product": "もっと見る",
                "Bone folder image": "骨のフォルダーの画像",
                "bone folder": "骨のフォルダー",
                "Our bone folder is a premium quality tool used by crafters and bookbinders alike. Our manufactured bone folders utilize high-quality materials, making them durable and precise.": "当社の骨のフォルダーは、クラフターや製本業者に使用されるプレミアム品質のツールです。当社が製造する骨のフォルダーは高品質な材料を使用しており、耐久性と精度が優れています。",
                "Bone dice image": "骨のサイコロの画像",
                "bone dice": "骨のサイコロ",
                "Add a touch of ancient charm to your tabletop games with our handcrafted Bone Dice. Made from natural bone, these unique dice bring a sense of history and mystique to your gameplay experience.": "当社の手作りの骨のサイコロで、テーブルトップゲームに古代の魅力を加えましょう。自然な骨で作られたこれらのユニークなサイコロは、プレイ体験に歴史と神秘的な感覚をもたらします。",
                "Guitar component image": "ギターコンポーネントの画像",
                "guitar component": "ギターコンポーネント",
                "Discover the unique sound of our bone guitar components. Crafted from animal bone, the nut or saddle adds warmth and sustain, enhancing the overall tone of your instrument.": "当社の骨のギターコンポーネントのユニークな音を発見してください。動物の骨から作られたナットやサドルが、楽器の全体的な音色を豊かにし、サステインを向上させます。",
                "Pen blanks image": "ペンブランクの画像",
                "pen blanks": "ペンブランク",
                "Create unique pens with our bone pen blanks, made from high-quality animal bone. Durable and distinctive, our blanks offer a variety of shapes and sizes to elevate your pen-making craft.": "高品質な動物の骨から作られた当社の骨のペンブランクでユニークなペンを作りましょう。耐久性があり独特で、さまざまな形状とサイズを提供し、ペン作りの技術を高めます。",
                "Drinking horn image": "飲み用角の画像",
                "drinking horn": "飲み用角",
                "Unleash your wild side with our genuine animal drinking horns, crafted from naturally shed antlers and horns. A rustic, eco-friendly way to enjoy your favorite beverages.": "当社の本物の動物飲み用角で野生の一面を解放してください。自然に落ちた角や角から作られたこれらの角は、お気に入りの飲み物を楽しむための素朴で環境に優しい方法です。",

                "Our Vision & Experts, Quality": "私たちのビジョン & 専門家、品質",
                "Vision": "ビジョン",
                "Our main priority is to attain absolute customer satisfaction for which our team works efficiently to keep the product's quality. Moreover, our team.": "私たちの主な優先事項は、顧客満足を絶対的に達成することであり、そのために私たちのチームは製品の品質を維持するために効率的に働きます。さらに、私たちのチーム。",
                "Experts": "専門家",
                "Our experts have a deep knowledge of dominant market trends. Therefore they have practiced enough to appreciate the exact obligation of the client.": "私たちの専門家は、市場の主要なトレンドについて深い知識を持っています。したがって、彼らはクライアントの正確な要求を理解するために十分に練習しています。",
                "Quality": "品質",
                "Apart from the compliance of different products with global quality standards, we have become a good choice for our clients. We focus more on ethical.": "さまざまな製品が国際的な品質基準に準拠しているだけでなく、私たちはクライアントにとって良い選択肢となっています。私たちは倫理的な側面にも重点を置いています。",
                "In promotion and advertising, a testimonial consists of a person's experience in a written statement extolling the integrity of a product or services.": "プロモーションと広告において、証言は、製品やサービスの誠実さを称賛する書面による声明での個人の経験で構成されます。",
                "Get More Info.": "もっと詳しく知る。",

                "Welcome to our Website": "私たちのウェブサイトへようこそ",
                "About us Located in Ghaziabad, Uttar Pradesh (India), we at Gift Mart was established in the year 2015.": "私たちについて インディアのウッタル・プラデーシュ州ガジアバードに位置するGift Martは、2015年に設立されました。",
                "We are one of the preeminent Suppliers of exquisite range that includes Wooden and Resin Coaster Set, Natural Horn Comb, Bone Hair Comb, Wooden Hair Comb, Horn Drinking Mugs, Horn Bone Jewelry Box, Wooden MDF Box, Mother of Pearl Box, Buffalo Horn Box, Wooden Lamp and Buffalo Horn Toggles, etc.": "私たちは、木製および樹脂製のコースターセット、天然角のコーム、骨製のコーム、木製のコーム、角製の飲み物マグ、角骨のジュエリーボックス、木製MDFボックス、真珠母のボックス、バッファロー角のボックス、木製のランプ、バッファロー角のトグルなどを含む、精巧な製品の主要なサプライヤーの一つです。",
                "Apart from the compliance of different products with global quality standards, we have become a good choice for our clients. We focus more on ethical business policies and help the clients with open quality products. Additionally, to answer the needs of the clients, we make proper quality testing of the products at different levels. Our experts have a deep knowledge of dominant market trends.": "さまざまな製品が国際的な品質基準に準拠しているだけでなく、私たちはクライアントにとって良い選択肢となっています。私たちは倫理的なビジネスポリシーにもっと重点を置き、クライアントに対してオープンな品質の製品を提供します。さらに、クライアントのニーズに応えるために、製品の適切な品質テストを異なるレベルで実施しています。私たちの専門家は、市場の主要なトレンドについて深い知識を持っています。",
                "Read More.": "続きを読む。",

                "Our": "私たちの",
                "Shipping Partners": "配送パートナー",
                "Shipping partner": "配送パートナー",
                
                "Popular": "人気",
                "Products": "製品",
                "Camel Bone Dominoes": "キャメルボーン・ドミノ",
                "Many folks have asked us to expand our original 6x6 Dominos game into a 9x9 configuration and we aim to please! In this edition, we added 27 new tiles (for a total of 55 hand-carved dominos/set) essentially doubling the amount of dominos/set.": "多くの方から、元々の6x6ドミノゲームを9x9の構成に拡張して欲しいとのリクエストをいただきました。今回のエディションでは、27枚の新しいタイルを追加しました（合計55枚の手彫りドミノ/セット）で、実質的にドミノ/セットの枚数が倍増します。",
                "Bone Clasp": "骨のクラスプ",
                "Bone clasps are useful to add the finishing touch to Japanese bookbinding styles and can be used in creative ways. These genuine bone clasps are one inch in length.": "骨のクラスプは、日本の製本スタイルに仕上げのアクセントを加えるのに役立ち、創造的な方法で使用することができます。これらの本物の骨のクラスプは、長さが1インチです。",
                "Dyed Bone Nut Blank": "染めた骨のナットブランク",
                "Unleash the tone of your guitar with our premium bone nuts, crafted from sustainably sourced animal bones. Our expert luthiers carefully select and shape each nut to bring out its unique characteristics, resulting in a rich, warm sound": "私たちのプレミアム骨ナットでギターの音を引き出しましょう。持続可能に調達された動物の骨から作られたものです。専門のリュート職人がそれぞれのナットを慎重に選び、形を整えて、その独自の特性を引き出し、豊かで暖かい音を実現します。",
                "Guitar Bridge Pin": "ギター用ブリッジピン",
                "Whether crafted from camel bones, buffalo horns, or fossilized ivory, each piece is precision-made to perfectly complement your instrument.": "キャメルの骨、バッファローの角、または化石化した象牙から作られたものであれ、それぞれの部品は精密に作られており、あなたの楽器に完璧に合わせることができます。",
                "Guitar Slides": "ギター用スライド",
                "Transform your guitar's sound with our premium Guitar animals Inlay, crafted from sustainably sourced materials. Our skilled artisans carefully design each inlay to optimize resonance and sustain, delivering a rich, vibrant tone.": "私たちのプレミアムギターアニマルインレイで、ギターの音を変革しましょう。持続可能に調達された材料から作られています。熟練の職人がそれぞれのインレイを慎重に設計し、共鳴とサステインを最適化し、豊かで生き生きとした音を実現します。",
                "Camel Bone Knife Handle": "キャメルボーンナイフハンドル",
                "Our skilled artisans carefully design each component to optimize resonance and sustain, delivering a rich, vibrant tone. Whether crafted from intricate designs, exotic woods, or precious stones, each piece is precision-made to perfectly complement your instrument.": "私たちの熟練した職人が、共鳴とサステインを最適化するように各コンポーネントを慎重に設計し、豊かで鮮やかな音を実現します。複雑なデザイン、エキゾチックな木材、または貴重な石から作られたものであれ、それぞれの部品は精密に作られており、あなたの楽器に完璧に合わせます。",
                "Water Buffalo Horn Smooth": "スムースバッファローホーン",
                "Enhance your instrument's sound with our premium Water Buffalo Horn, crafted from sustainably sourced materials. Our skilled artisans carefully design each component to optimize resonance and sustain, delivering a rich, vibrant tone": "私たちのプレミアム水牛の角で、楽器の音を強化しましょう。持続可能に調達された材料から作られています。熟練の職人がそれぞれのコンポーネントを慎重に設計し、共鳴とサステインを最適化し、豊かで生き生きとした音を実現します。",
                "Recone True Stone": "リコーン・トゥルーストーン",
                "Elevate your performance with our premium Animals, Giraffe Bone Look, Recone True Stone and X-Grip Handle, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize comfort and control, delivering a confident, expressive show.": "私たちのプレミアム製品でパフォーマンスを向上させましょう。動物、キリンの骨の見た目、リコーン・トゥルーストーン、X-グリップハンドルなど、専門的に選定された材料から作られています。熟練の職人が各要素を丁寧に設計し、快適さとコントロールを最大化し、自信に満ちた表現力豊かなショーを実現します。",
                "Stabilized Wood Handle": "安定化木製ハンドル",
                "Elevate your performance with our premium Sheep Horn Handles, Ox-Zebu Horn Handles and Stabilized Wood Handle, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize comfort and control, delivering a confident, expressive show.": "私たちのプレミアム製品でパフォーマンスを向上させましょう。羊の角のハンドル、オックスゼブの角のハンドル、安定化木製ハンドルなど、専門的に選定された材料から作られています。熟練の職人が各要素を丁寧に設計し、快適さとコントロールを最大化し、自信に満ちた表現力豊かなショーを実現します。",
                "Buffalo Bone Hair Pipe Beads": "バッファローボーン・ヘアパイプビーズ",
                "Real bone hair pipe beads are meticulously crafted from authentic bone, offering a unique and natural texture. Each bead is hand-carved to reveal intricate patterns and a warm, organic feel.": "本物の骨のヘアパイプビーズは、真正な骨から精密に作られており、ユニークで自然なテクスチャーを提供します。各ビーズは手彫りされ、複雑なパターンと温かみのある有機的な感触を表現します。",
                "Teflon Bone Folder": "テフロン・ボーンフォルダー",
                "Elevate your bookbinding experience with our premium Teflon Bone Folder, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize precision and control, delivering a confident, professional finish.": "私たちのプレミアムテフロン・ボーンフォルダーで、製本体験を向上させましょう。専門的に選定された材料から作られています。熟練の職人が各要素を丁寧に設計し、精度とコントロールを最大化し、自信に満ちたプロフェッショナルな仕上がりを実現します。",
                "More Product": "もっと見る",

                "Let's Connect With Us": "一緒に繋がりましょう",
                "*Your Name": "*お名前",
                "Full Name is Required": "フルネームは必須です",
                "*E-Mail": "*メールアドレス",
                "Enter your email": "メールアドレスを入力してください",
                "Please enter a valid email": "有効なメールアドレスを入力してください",
                "*Your Phone Number": "*電話番号",
                "Phone number is required": "電話番号は必須です",
                "*Organization Name": "*組織名",
                "*Products/Service Looking For": "*探している製品/サービス",
                "Please enter your products": "製品を入力してください",
                "*Select Your Country": "*国を選択してください",
                "India": "インド",
                "United States": "アメリカ合衆国",
                "Japan": "日本",
                "Australia": "オーストラリア",
                "Canada": "カナダ",
                "China": "中国",
                "Germany": "ドイツ",
                "United Kingdom": "イギリス",
                "Russia": "ロシア",
                "*Describe Your Requirements": "*要件を記述してください",
                "Enter your Message": "メッセージを入力してください",
                "Submit": "送信",
                "Success. Message sent successfully": "成功。メッセージが正常に送信されました",
                "Something went wrong. Please try later.": "何かがうまくいきませんでした。後でもう一度お試しください。",
                "RC Creation Website Form": "RCクリエーションウェブサイトフォーム",
                "New Contact Message from your Website": "あなたのウェブサイトからの新しい連絡メッセージ",
                "alt": "在宅勤務の画像",

                "Whatsapp Chat": "Whatsapp チャット",
                "RC Creation found 1985 was a cottage industries, now are the world wide largest Exporter , with an affirmation to manufacturing and retailing a high-quality range of Camel bone and water buffalo horn , Wooden and Leather Handicraft Items.": "RC Creation は 1985 年に設立され、コテージ産業として始まり、現在では高品質のラクダの骨や水牛の角、木材や革の工芸品を専門とする世界最大の輸出業者です。",
                "Explore": "探検",
                "About Us": "私たちに関しては",
                "Home": "ホーム",
                "Contact Us": "お問い合わせ",
                "Follow Us": "フォローする",
                "Our Products": "私たちの製品",
                "Knife Handles": "ナイフハンドル",
                "Teflon Bone Folder": "テフロンボーンフォルダー",
                "Horn Saddle": "ホーンサドル",
                "Guitar Components": "ギターコンポーネント",
                "Dominoes Set": "ドミノセット",
                "Head Office Address": "本社住所",
                "Khasra No: 1703/1, Toli Mohalla, Mohalla Purvi Jatav, LONI – Ghaziabad-201102, UP, INDIA": "Khasra No: 1703/1, Toli Mohalla, Mohalla Purvi Jatav, LONI – Ghaziabad-201102, UP, INDIA",
                "+91 9266-116358": "+91 9266-116358",
                "+91 11-4227-3742": "+91 11-4227-3742",
                "info@bonehornmart.com": "info@bonehornmart.com",
                "rccreation.team@yahoo.com": "rccreation.team@yahoo.com",
                "Copyright &#169; 1985-2024": "著作権 © 1985-2024",
                "All Rights Reserved.": "全著作権所有."


            }
        },
        il: {
            translation: {
                "Handicrafts Manufacturer": "Produttore di artigianato",
                "buffalo bone": "osso di bufalo",
                "welcome to": "benvenuto a",
                "RC CREATION": "RC CREATION",
                "Bone & Horn, Wooden Handicrafts Manufacturer & Exporter Worldwide.": "Produttore ed esportatore mondiale di artigianato in osso e corno e in legno.",
                "Knife Handle": "Manico del coltello",
                "Bone Folder": "Cartella ossea",
                "We provide premium quality Camel bone and water buffalo horn": "Forniamo osso di cammello e corno di bufalo d'acqua di qualità superiore",
                "Guitar Component": "Componente della chitarra",
                "Dominoes Set": "Set di domino",
                "Wooden and Leather Handicraft Items, to make sure": "Articoli artigianali in legno e pelle, per assicurarsi",
                "that these meet the industry quality standards": "che questi soddisfino gli standard di qualità del settore",
                "Horn Beads": "Perline di corno",
                "Bone Button": "Pulsante osseo",
                "We provide premium quality Camel bone and water buffalo horn, Wooden and Leather Handicraft Items.": "Forniamo articoli artigianali in osso di cammello e corno di bufalo d'acqua, in legno e pelle di qualità superiore.",
                "Bone Dice": "Dadi d'osso",
                "Bone Clasp": "Chiusura ossea",

                "Latest": "Ultimi",
                "Products": "Prodotti",
                "Knife handle image": "Immagine del manico del coltello",
                "bone handle": "manico in osso",
                "Our expertly crafted bone knife handles are made from premium animal bone, precision-fitted to ensure a comfortable grip and durable performance, perfect for discerning knife enthusiasts and collectors.": "I nostri manici di coltello in osso, realizzati con cura, sono fatti di osso di animale di alta qualità, adattati con precisione per garantire una presa comoda e prestazioni durevoli, perfetti per gli appassionati e collezionisti di coltelli più esigenti.",
                "More Product": "Altri Prodotti",
                "Bone folder image": "Immagine del piegafogli in osso",
                "bone folder": "piegafogli in osso",
                "Our bone folder is a premium quality tool used by crafters and bookbinders alike. Our manufactured bone folders utilize high-quality materials, making them durable and precise.": "Il nostro piegafogli in osso è uno strumento di alta qualità utilizzato da artigiani e rilegatori. I nostri piegafogli in osso sono realizzati con materiali di alta qualità, rendendoli durevoli e precisi.",
                "Bone dice image": "Immagine dei dadi in osso",
                "bone dice": "dadi in osso",
                "Add a touch of ancient charm to your tabletop games with our handcrafted Bone Dice. Made from natural bone, these unique dice bring a sense of history and mystique to your gameplay experience.": "Aggiungi un tocco di fascino antico ai tuoi giochi da tavolo con i nostri dadi in osso fatti a mano. Realizzati in osso naturale, questi dadi unici portano un senso di storia e mistero alla tua esperienza di gioco.",
                "Guitar component image": "Immagine del componente della chitarra",
                "guitar component": "componente della chitarra",
                "Discover the unique sound of our bone guitar components. Crafted from animal bone, the nut or saddle adds warmth and sustain, enhancing the overall tone of your instrument.": "Scopri il suono unico dei nostri componenti per chitarra in osso. Realizzati in osso animale, il capotasto o il ponte aggiungono calore e sustain, migliorando il tono complessivo del tuo strumento.",
                "Pen blanks image": "Immagine dei blocchi per penna",
                "pen blanks": "blocchi per penna",
                "Create unique pens with our bone pen blanks, made from high-quality animal bone. Durable and distinctive, our blanks offer a variety of shapes and sizes to elevate your pen-making craft.": "Crea penne uniche con i nostri blocchi per penna in osso, realizzati con osso di alta qualità. Resistenti e distintivi, i nostri blocchi offrono una varietà di forme e dimensioni per elevare il tuo mestiere nella creazione di penne.",
                "Drinking horn image": "Immagine del corno da bere",
                "drinking horn": "corno da bere",
                "Unleash your wild side with our genuine animal drinking horns, crafted from naturally shed antlers and horns. A rustic, eco-friendly way to enjoy your favorite beverages.": "Scopri il tuo lato selvaggio con i nostri veri corni da bere in animale, realizzati con corna e legni naturali. Un modo rustico ed ecologico per gustare le tue bevande preferite.",

                "Our Vision & Experts, Quality": "La Nostra Visione & Esperti, Qualità",
                "Vision": "Visione",
                "Our main priority is to attain absolute customer satisfaction for which our team works efficiently to keep the product's quality. Moreover, our team.": "La nostra principale priorità è raggiungere una totale soddisfazione del cliente per la quale il nostro team lavora in modo efficiente per mantenere la qualità del prodotto. Inoltre, il nostro team.",
                "Experts": "Esperti",
                "Our experts have a deep knowledge of dominant market trends. Therefore they have practiced enough to appreciate the exact obligation of the client.": "I nostri esperti hanno una conoscenza approfondita delle tendenze dominanti del mercato. Pertanto, hanno praticato abbastanza per apprezzare l'esatta obbligazione del cliente.",
                "Quality": "Qualità",
                "Apart from the compliance of different products with global quality standards, we have become a good choice for our clients. We focus more on ethical.": "Oltre alla conformità dei diversi prodotti agli standard globali di qualità, siamo diventati una buona scelta per i nostri clienti. Ci concentriamo di più sull'etica.",
                "In promotion and advertising, a testimonial consists of a person's experience in a written statement extolling the integrity of a product or services.": "Nella promozione e nella pubblicità, una testimonianza consiste nell'esperienza di una persona in una dichiarazione scritta che esalta l'integrità di un prodotto o di servizi.",
                "Get More Info.": "Ottieni Maggiori Informazioni.",

                "Welcome to our Website": "Benvenuto nel nostro sito web",
                "About us Located in Ghaziabad, Uttar Pradesh (India), we at Gift Mart was established in the year 2015.": "Chi siamo: Situati a Ghaziabad, Uttar Pradesh (India), noi di Gift Mart siamo stati fondati nell'anno 2015.",
                "We are one of the preeminent Suppliers of exquisite range that includes Wooden and Resin Coaster Set, Natural Horn Comb, Bone Hair Comb, Wooden Hair Comb, Horn Drinking Mugs, Horn Bone Jewelry Box, Wooden MDF Box, Mother of Pearl Box, Buffalo Horn Box, Wooden Lamp and Buffalo Horn Toggles, etc.": "Siamo uno dei principali fornitori di una gamma squisita che include set di sottobicchieri in legno e resina, pettine in corno naturale, pettine in osso, pettine in legno, tazze da bere in corno, scatola di gioielli in corno e osso, scatola in MDF di legno, scatola in madreperla, scatola in corno di bufalo, lampada in legno e fermagli in corno di bufalo, ecc.",
                "Apart from the compliance of different products with global quality standards, we have become a good choice for our clients.": "Oltre alla conformità di diversi prodotti con gli standard di qualità globali, siamo diventati una scelta valida per i nostri clienti.",
                "We focus more on ethical business policies and help the clients with open quality products.": "Ci concentriamo maggiormente su politiche aziendali etiche e aiutiamo i clienti con prodotti di qualità trasparente.",
                "Additionally, to answer the needs of the clients, we make proper quality testing of the products at different levels.": "Inoltre, per rispondere alle esigenze dei clienti, effettuiamo test di qualità adeguati dei prodotti a diversi livelli.",
                "Our experts have a deep knowledge of dominant market trends.": "I nostri esperti hanno una profonda conoscenza delle tendenze dominanti del mercato.",
                "Read More.": "Leggi di più.",

                "Our": "I nostri",
                "Shipping Partners": "Partner di Spedizione",
                "Shipping partner": "Partner di Spedizione",

                "Popular": "Popolare",
                "Products": "Prodotti",
                "Camel Bone Dominoes": "Domino di Osso di Camello",
                "Many folks have asked us to expand our original 6x6 Dominos game into a 9x9 configuration and we aim to please! In this edition, we added 27 new tiles (for a total of 55 hand-carved dominos/set) essentially doubling the amount of dominos/set.": "Molte persone ci hanno chiesto di espandere il nostro gioco di dominò 6x6 originale a una configurazione 9x9 e ci impegniamo a soddisfare! In questa edizione, abbiamo aggiunto 27 nuove tessere (per un totale di 55 dominò intagliati a mano/set) raddoppiando essenzialmente la quantità di dominò/set.",
                "Bone Clasp": "Chiusura in Osso",
                "Bone clasps are useful to add the finishing touch to Japanese bookbinding styles and can be used in creative ways. These genuine bone clasps are one inch in length.": "Le chiusure in osso sono utili per dare il tocco finale agli stili di rilegatura giapponese e possono essere utilizzate in modi creativi. Queste chiusure in osso autentico hanno una lunghezza di un pollice.",
                "Dyed Bone Nut Blank": "Silletto in Osso Tinto",
                "Unleash the tone of your guitar with our premium bone nuts, crafted from sustainably sourced animal bones. Our expert luthiers carefully select and shape each nut to bring out its unique characteristics, resulting in a rich, warm sound": "Scatena il tono della tua chitarra con i nostri silletto in osso premium, realizzati con ossa di animali provenienti da fonti sostenibili. I nostri liutai esperti selezionano e modellano accuratamente ciascun silletto per evidenziare le sue caratteristiche uniche, offrendo un suono ricco e caldo.",
                "Guitar Bridge Pin": "Perno del Ponte per Chitarra",
                "Whether crafted from camel bones, buffalo horns, or fossilized ivory, each piece is precision-made to perfectly complement your instrument.": "Che sia realizzato con ossa di cammello, corni di bufalo o avorio fossilizzato, ogni pezzo è realizzato con precisione per completare perfettamente il tuo strumento.",
                "Guitar Slides": "Scivoli per Chitarra",
                "Transform your guitar's sound with our premium Guitar animals Inlay, crafted from sustainably sourced materials. Our skilled artisans carefully design each inlay to optimize resonance and sustain, delivering a rich, vibrant tone.": "Trasforma il suono della tua chitarra con la nostra Incrustazione Animali per Chitarra premium, realizzata con materiali provenienti da fonti sostenibili. I nostri abili artigiani progettano ogni incrustazione con attenzione per ottimizzare la risonanza e la durata, offrendo un tono ricco e vibrante.",
                "Camel Bone Knife Handle": "Manico del Coltello in Osso di Cammello",
                "Our skilled artisans carefully design each component to optimize resonance and sustain, delivering a rich, vibrant tone. Whether crafted from intricate designs, exotic woods, or precious stones, each piece is precision-made to perfectly complement your instrument.": "I nostri abili artigiani progettano con cura ogni componente per ottimizzare la risonanza e la durata, offrendo un tono ricco e vibrante. Che sia realizzato con disegni intricati, legni esotici o pietre preziose, ogni pezzo è realizzato con precisione per completare perfettamente il tuo strumento.",
                "Water Buffalo Horn Smooth": "Corno di Bufalo d'Acqua Liscio",
                "Enhance your instrument's sound with our premium Water Buffalo Horn, crafted from sustainably sourced materials. Our skilled artisans carefully design each component to optimize resonance and sustain, delivering a rich, vibrant tone": "Migliora il suono del tuo strumento con il nostro Corno di Bufalo d'Acqua premium, realizzato con materiali provenienti da fonti sostenibili. I nostri artigiani esperti progettano con attenzione ogni componente per ottimizzare la risonanza e la durata, offrendo un tono ricco e vibrante.",
                "Recone True Stone": "Recone Pietra Vera",
                "Elevate your performance with our premium Animals, Giraffe Bone Look, Recone True Stone and X-Grip Handle, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize comfort and control, delivering a confident, expressive show.": "Migliora la tua performance con i nostri prodotti premium Animali, Aspetto di Osso di Giraffa, Recone Pietra Vera e Manico X-Grip, realizzati con materiali selezionati con cura. I nostri abili artigiani progettano con precisione ogni elemento per massimizzare il comfort e il controllo, offrendo uno spettacolo sicuro ed espressivo.",
                "Stabilized Wood Handle": "Manico in Legno Stabilizzato",
                "Elevate your performance with our premium Sheep Horn Handles, Ox-Zebu Horn Handles and Stabilized Wood Handle, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize comfort and control, delivering a confident, expressive show.": "Migliora la tua performance con i nostri Manici in Corno di Pecora, Corno di Ox-Zebu e Legno Stabilizzato premium, realizzati con materiali selezionati con cura. I nostri artigiani esperti progettano ogni elemento con attenzione per massimizzare il comfort e il controllo, offrendo uno spettacolo sicuro ed espressivo.",
                "Buffalo Bone Hair Pipe Beads": "Perline di Tubo in Osso di Bufalo",
                "Real bone hair pipe beads are meticulously crafted from authentic bone, offering a unique and natural texture. Each bead is hand-carved to reveal intricate patterns and a warm, organic feel.": "Le perline di tubo in osso reale sono realizzate con cura a partire da osso autentico, offrendo una texture unica e naturale. Ogni perla è scolpita a mano per rivelare motivi intricati e una sensazione organica calda.",
                "Teflon Bone Folder": "Pieghevole in Osso Teflonato",
                "Elevate your bookbinding experience with our premium Teflon Bone Folder, crafted from expertly selected materials. Our skilled craftsmen meticulously design each element to maximize precision and control, delivering a confident, professional finish.": "Migliora la tua esperienza di rilegatura con il nostro Pieghevole in Osso Teflonato premium, realizzato con materiali selezionati con cura. I nostri abili artigiani progettano con precisione ogni elemento per massimizzare la precisione e il controllo, offrendo una finitura professionale e sicura.",
                "More Product": "Altri Prodotti",

                "Let's Connect With Us": "Connettiamoci con noi",
                "*Your Name": "*Il tuo nome",
                "Full Name is Required": "Il nome completo è richiesto",
                "*E-Mail": "*E-Mail",
                "Enter your email": "Inserisci la tua email",
                "Please enter a valid email": "Per favore inserisci una email valida",
                "*Your Phone Number": "*Il tuo numero di telefono",
                "Phone number is required": "Il numero di telefono è richiesto",
                "*Organization Name": "*Nome dell'organizzazione",
                "*Products/Service Looking For": "*Prodotti/Servizio Ricercato",
                "Please enter your products": "Per favore inserisci i tuoi prodotti",
                "*Select Your Country": "*Seleziona il tuo paese",
                "India": "India",
                "United States": "Stati Uniti",
                "Japan": "Giappone",
                "Australia": "Australia",
                "Canada": "Canada",
                "China": "Cina",
                "Germany": "Germania",
                "United Kingdom": "Regno Unito",
                "Russia": "Russia",
                "*Describe Your Requirements": "*Descrivi le tue esigenze",
                "Enter your Message": "Inserisci il tuo messaggio",
                "Submit": "Invia",
                "Success. Message sent successfully": "Successo. Messaggio inviato con successo",
                "Something went wrong. Please try later.": "Qualcosa è andato storto. Per favore riprova più tardi.",
                "RC Creation Website Form": "Modulo sito web RC Creation",
                "New Contact Message from your Website": "Nuovo messaggio di contatto dal tuo sito web",
                "alt": "Immagine lavoro da casa",

                "Whatsapp Chat": "Chat Whatsapp",
                "RC Creation found 1985 was a cottage industries, now are the world wide largest Exporter , with an affirmation to manufacturing and retailing a high-quality range of Camel bone and water buffalo horn , Wooden and Leather Handicraft Items.": "RC Creation, fondata nel 1985, è iniziata come un'industria artigianale ed è ora il più grande esportatore mondiale, specializzato in articoli artigianali di alta qualità in osso di cammello e corno di bufalo d'acqua, legno e pelle.",
                "Explore": "Esplora",
                "About Us": "Chi Siamo",
                "Home": "Home",
                "Contact Us": "Contattaci",
                "Follow Us": "Seguici",
                "Our Products": "I Nostri Prodotti",
                "Knife Handles": "Manici di Coltelli",
                "Teflon Bone Folder": "Piegafogli in Teflon",
                "Horn Saddle": "Sella in Corno",
                "Guitar Components": "Componenti per Chitarra",
                "Dominoes Set": "Set di Domino",
                "Head Office Address": "Indirizzo della Sede Centrale",
                "Khasra No: 1703/1, Toli Mohalla, Mohalla Purvi Jatav, LONI – Ghaziabad-201102, UP, INDIA": "Khasra No: 1703/1, Toli Mohalla, Mohalla Purvi Jatav, LONI – Ghaziabad-201102, UP, INDIA",
                "+91 9266-116358": "+91 9266-116358",
                "+91 11-4227-3742": "+91 11-4227-3742",
                "info@bonehornmart.com": "info@bonehornmart.com",
                "rccreation.team@yahoo.com": "rccreation.team@yahoo.com",
                "Copyright &#169; 1985-2024": "Copyright © 1985-2024",
                "All Rights Reserved.": "Tutti i Diritti Riservati."

            }

        }
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
